import React, {useState} from 'react';

const App = () => {

  const [allVal, setVal] = useState({
    inputName: "",
    inputPass: "",
  });


  const inputEvent = (event) => {

    console.log(event);
    // console.log(event.target.value);
    // console.log(event.target.name);

    // const name = event.target.name;
    // const value = event.target.value;
    const {name, value} = event.target;

    setVal((prevValue) => {
        console.log(prevValue);

          return {
            ...prevValue,
            [name] : value,
          }

    });

    // setNameVal(event.target.value);
  };

  const onSubmits = (event) => {
    event.preventDefault();
    alert('Form submitted');
  };

  return (
    <>

    <form onSubmit={onSubmits}>
    <div>
        <h1>Hello {allVal.inputName} {allVal.inputPass}</h1>
        <input type="text" placeholder="Enter Your Name" name="inputName" onChange={inputEvent} value={allVal.inputName} /> 
        <br/>
        <input type="text" placeholder="Enter Your Name" name="inputPass" onChange={inputEvent} value={allVal.inputPass} /> 
        <button type="submit" >Click Me :smile</button>
    </div>
    </form>

    </>
    );
};

export default App;